import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { SingleColumnImpact } from '@bestfit-team/bf-base-ui';

const StudentsImpact = ({ ...props }) => {
  const copy = useStaticQuery(graphql`
    query StudentsImpactQuery {
      studentsImpactContent: airtable(data: {Segment: {eq: "Students_Impact"}}) {
      data {
        header
        attached_images {
          url
        }
      }
    }
  }
`);

console.log(JSON.stringify(copy))
  return (
    <>
      <SingleColumnImpact
        impactCopy={copy.studentsImpactContent.data.header}
      />
    </>
  )
}

export default StudentsImpact;
