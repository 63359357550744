import React from "react";
import { graphql, useStaticQuery } from 'gatsby';
import { Heading, HorizontalDivider } from '@bestfit-team/bf-base-ui';
import Layout from "../components/layout";
import StudentsHero from "../sections/students/StudentsHero";
import StudentsImpact from "../sections/students/StudentsImpact";
import StudentContactUs from "../sections/students/StudentContactUs";

// TODO: #21 ForStudents - hourney
// TODO: #22 ForStudents - stats
// TODO: #23 ForStudents - resource list
// TODO: #24 ForStudents - how it works
// TODO: #25 ForStudents - latest resource updates
// TODO: #26 ForStudents - bring bestfit
// TODO: #44 ForStudents - top-margin for header component [no whitespace above pic]

const ForStudents = ({ data }) => {
  console.log(data)
  return (
    <Layout
      pageTitle='For Students'
    >
      <StudentsHero />

      <StudentsImpact />
      <Heading h2> journey </Heading>
      <HorizontalDivider />
      <Heading h2> by the numbers </Heading>
      <HorizontalDivider />
      <Heading h2> resource list </Heading>
      <HorizontalDivider />
      <Heading h2> how it works </Heading>
      <HorizontalDivider />
      <Heading h2> resource updates </Heading>
      <HorizontalDivider />
      {/* <Heading h2> bring bestfit </Heading> */}
      <StudentContactUs />
      <HorizontalDivider />
      <Heading h2> footer </Heading>

    </Layout>
  )
}

export const query = graphql`
  query ForStudentsPageContentQuery {
    StudentsHeader: airtable(
      data: {Segment: {eq: "Students_Header"}}, 
      ) {
        data {
          header
          status
          subheader
          action
          Segment
          action_url
          attached_images {
            filename
            url
          }
        }
    }
  }
`

export default ForStudents;