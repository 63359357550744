import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { Hero } from '@bestfit-team/bf-base-ui';

const StudentsHero = () => {
  const copy = useStaticQuery(graphql`
    query StudentsHeroQuery {
      StudentsHeroInfo: airtable(
        data: {Segment: {eq: "Students_Header"}}, 
        ) {
          data {
            header
            status
            subheader
            action
            Segment
            action_url
            attached_images {
              filename
              url
            }
          }
      }
    }
  `);

console.log(JSON.stringify(copy))
  return (
    <>
      <Hero 
        headingCopy={copy.StudentsHeroInfo.data.header}
        subheadingCopy={copy.StudentsHeroInfo.data.subheader}
        buttonLabel={copy.StudentsHeroInfo.data.action}
        buttonUrl={copy.StudentsHeroInfo.data.action_url}
        heroImageUrl={copy.StudentsHeroInfo.data.attached_images[0].url}
        heroImageAlt={copy.StudentsHeroInfo.data.attached_images[0].filename}
      />
    </>
  )
}

export default StudentsHero;
